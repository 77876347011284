<template>
    <div style="text-align: center"><h1>微信支付:{{chargeForm.payAmount}}元</h1></div>
    <div class="qrcode" ref="qrCodeUrl"></div>
</template>

<script>
    import QRCode from 'qrcodejs2'
  import {pointsCharge} from "../../api/integral";
  export default {
    name: "weixin",
    data(){
      return{
        chargeForm:'',
      }
    },
    methods:{
      creatQrcode(chargeForm){
       // console.log(chargeForm);
        pointsCharge(chargeForm).then(response=>{
          let qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: response.data, // weixin://wxpay/bizpayurl?pr=sZz****
            width: 194,
            height: 194,
            colorDark: "black",
            colorLight: "white",
            correctLevel: QRCode.CorrectLevel.H,
          });

        })
      }
    },
    mounted(){
      this.chargeForm = JSON.parse(this.$route.params.chargeForm)
     // console.log(this.chargeForm)
      this.creatQrcode(this.chargeForm)
    }
  };
</script>

<style scoped>
.qrcode{
    margin-left: 44%;
}
</style>